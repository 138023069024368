export const environment = {
  environment: 'staging',
  develop: false,
  production: false,
  maserati_services_endpoint: 'https://www-test.configurator.maserati.com',
  maserati_instock_endpoint: 'https://www-rc.maserati.com',
  autobahn_configuration: {
    broker_endpoint: 'wss://mxe-test.maserati.com/ws',
    realm: 'realm1',
    max_retries: 5,
    heartbeat: {
      heartBeatTimer: 15000,
      heartbeat_max_retries: 4,
      heartbeat_retry_after: 15000
    },
    pairing_streaming_session_retry_after: 5000,
    pairing_not_compressed_message_max_size: 1000
  },
  authentication: {
    maserati_authentication_endpoint: 'https://sts-dev.fiat.com/adfs/oauth2',
    client_id: 'a6a6ca8f-1a25-4d04-bb2f-cf1e695eade9',
    resource: 'MXE',
    redirect_uri: 'https://mxe-test.maserati.com/mxe/oauth/redirecturi',
  },
  monkeyWay: {
    idleTime: 300, //seconds
    localStreamingOptions: {
      baseUrl: 'https://general.gorillastreaming.com',
      appEnvId: '406f3367-4ac0-4195-b273-60ebc2938003',
      apiKey: ""
    }
  },
  homepage_url: 'https://mxe-test.maserati.com',
  image_repo_url: 'https://configurator-test.maserati.com/ccbe/public/api/imageMapper/repo',
  enable_monkey_way_streaming: true,
  photocopier_endpoint: 'https://ph-test.cloud.maserati.com',
  country_selector_enabled: true,
  maseratiWsEndpoint: 'https://ws-test.maserati.com',
  endpointsaveWTLjson : 'https://wtl-cdntest.maserati.com/wtl/saveWTLjson.jsp',
  endpointModelsOfInterest: 'https://wtl-cdntest.maserati.com/wtl//jsonGenericLov.jsp',
  sentry_configuration: {
    dsn: "https://26fd2cb383f90d6df823afd24e4de837@o4508046702477312.ingest.de.sentry.io/4508244980400208",
    tracePropagationTargets: [/^https:\/\/mxe-test.maserati.com(\/.*)?$/, /^https:\/\/\w{8}\.staging\.local\.workers\.mxe-backend\.com(\/.*)?$/],
    replaysSessionSampleRate: 0.1,
    environment: 'staging'
  }
};